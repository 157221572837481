import axios from 'axios';
import Pagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Breadcrumbs from '../components/Breadcrumbs ';
import { useUsuarios } from '../context/UsuariosContext';


const Paquetes = () => {
  //----------------------------------------------------------------------------------------------------
  const { usuarios } = useUsuarios();
  const options = { headers: { authorization: "Bearer " + usuarios.token }, };
  const [paquetesturisticos, setPaquetesTuristicos] = useState([]);
  const [nombrePaqueteTuristico, setNombrePaqueteTuristico] = useState('');
  const [reseñaPaqueteTuristico, setReseñaPaqueteTuristico] = useState('');
  const [destinos, setDestinos] = useState([]);
  const [selectedDestino, setSelectedDestino] = useState("");
  const [destinoNombres, setDestinoNombres] = useState({});
  const [proveedores, setProveedores] = useState([]);
  const [razonsocialProveedor, setRazonsocialProveedor] = useState({});
  const [selectedProveedor, setSelectedProveedor] = useState('');
  const [estadoPaqueteTuristico, setEstadoPaqueteTuristico] = useState('')
  const [categoriaPaqueteTuristico, setCategoriaPaqueteTuristico] = useState('')
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPaquetes, setFilteredPaquetes] = useState([]); // Aquí almacenarás los Paquetes filtrados
  const [detallesPaquetesTuristicos, setDetallesPaquetesTuristicos] = useState([
    {
      nombredetallesPaqueteTuristico: '',
      precioDetallesPaqueteTuristico: '',
      isAdding: true,
    },
  ]);

  const [costosPaqueteTuristico, setCostosPaqueteTuristico] = useState([
    {
      nombredelcostoPaqueteTuristico: '',
      preciodelcostoPaqueteTuristico: '',
      isAdding: true,
    },
  ]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState('');
  const [edit, setEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  //----------------------------------------------------------------------------------------------------
  useEffect(() => {
    getData(page);
    obtenerDestinos();
    obtenerProveedores();
  }, [page]);
  //----------------------------------------------------------------------------------------------------
  const cleanData = () => {
    setNombrePaqueteTuristico('');
    setReseñaPaqueteTuristico('');
    setSelectedDestino('');

    setSelectedProveedor('');
    setEstadoPaqueteTuristico('');
    setCategoriaPaqueteTuristico('');
    setCostosPaqueteTuristico([
      {
        nombredelcostoPaqueteTuristico: '',
        preciodelcostoPaqueteTuristico: '',
        isAdding: false,
      },
    ]);
    setDetallesPaquetesTuristicos([
      {
        nombredetallesPaqueteTuristico: '',
        precioDetallesPaqueteTuristico: '',
        isAdding: false,
      },
    ]);
    setEdit(false);
  };
  //----------------------------------------------------------------------------------------------------
  const getData = async (pageCurrent) => {
    const { data } = await axios.get(`/api/paquetes/list?page=${pageCurrent}`, options);
    setPaquetesTuristicos(data.paquetesturisticos.docs);
    setFilteredPaquetes(data.paquetesturisticos.docs);
    setPage(data.paquetesturisticos.page);
    setTotalPages(data.paquetesturisticos.totalPages);
  };


  //----------------------------------------------------------------------------------------------------
  const onChangePage = (page) => {
    getData(page);
  };
  //----------------------------------------------------------------------------------------------------
  const savePaquete = async () => {
    try {
      if (!selectedDestino) {
        // Mostrar un mensaje de error o tomar alguna acción aquí
        return;
      }
      const newPaquete = {
        nombrePaqueteTuristico,
        reseñaPaqueteTuristico,
        destinos: selectedDestino,
        proveedores: selectedProveedor,
        estadoPaqueteTuristico,
        categoriaPaqueteTuristico,
        detallesPaqueteTuristico: detallesPaquetesTuristicos.map((detalle) => ({
          nombredetallesPaqueteTuristico: detalle.nombredetallesPaqueteTuristico,
          precioDetallesPaqueteTuristico: detalle.precioDetallesPaqueteTuristico,
        })),
        costosPaqueteTuristico: costosPaqueteTuristico.map((detalle) => ({
          nombredelcostoPaqueteTuristico: detalle.nombredelcostoPaqueteTuristico,
          preciodelcostoPaqueteTuristico: detalle.preciodelcostoPaqueteTuristico,
        })),
      };
      await axios.post('/api/paquetes/add', newPaquete, options);
      cleanData();
      getData();
      closeModal();
      // SweetAlert2 para mostrar éxito
      Swal.fire({
        icon: 'success',
        title: 'El paquete turístico ha sido registrado con éxito',
        showConfirmButton: false,
        timer: 1500,
      });

      setTimeout(() => {
        getData();
      }, 1000); // Espera 1 segundo antes de recargar
    } catch (error) {
      if (!error.response.data.ok) {
        return alert(error.response.data.message)
      }
      console.log('error en savePaquete', error.message);
    }
  }
  //----------------------------------------------------------------------------------------------------
  const updatePaquete = async () => {
    try {
      const id = localStorage.getItem('id');
      const newPaquete = {
        nombrePaqueteTuristico,
        reseñaPaqueteTuristico,
        destinos: selectedDestino, // Utiliza el destino seleccionado
        proveedores: selectedProveedor,
        estadoPaqueteTuristico,
        categoriaPaqueteTuristico,
        detallesPaqueteTuristico: detallesPaquetesTuristicos, // Agrega detallesPaqueteTuristico
        costosPaqueteTuristico: costosPaqueteTuristico, // Agregar los costos del paquete
      };
      // Realizar la solicitud PUT para actualizar el paquete turístico
      const { data } = await axios.put('/api/paquetes/' + id, newPaquete, options);
      setSelectedDestino(destinos)
      setSelectedProveedor(proveedores)


      // Limpiar los datos, cerrar el modal y realizar otras acciones necesarias
      cleanData();
      closeModal();
      getData();
      // Mostrar una notificación de éxito
      Swal.fire({
        icon: 'success',
        title: data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      if (!error.response.data.ok) {
        return alert(error.response.data.message);
      }
      console.log('Error en updatePaquete', error.message);
    }
  };
  //----------------------------------------------------------------------------------------------------
  const editData = (item) => {
    setEdit(true);
    setNombrePaqueteTuristico(item.nombrePaqueteTuristico || '');
    setReseñaPaqueteTuristico(item.reseñaPaqueteTuristico || '');
    setSelectedDestino(item.destinos || '');
    handleDestinoChange(item.destinos);

    setSelectedProveedor(item.proveedores || '');
    handleProveedorChange(item.proveedores);
    setEstadoPaqueteTuristico(item.estadoPaqueteTuristico || '');
    setCategoriaPaqueteTuristico(item.categoriaPaqueteTuristico || '');
    setDetallesPaquetesTuristicos(item.detallesPaqueteTuristico || []);
    setCostosPaqueteTuristico(item.costosPaqueteTuristico || []);
    localStorage.setItem('id', item._id);
    setIsModalOpen(true);
  };
  //----------------------------------------------------------------------------------------------------
  const closeModal = () => {
    setIsModalOpen(false);
  };
  //----------------------------------------------------------------------------------------------------
  const deletePaquete = async (id) => {
    try {
      Swal.fire({
        title: 'Esta seguro?',
        text: "No podras revertir el proceso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await axios.delete(`/api/paquetes/` + id, options);
          getData();
          Swal.fire({
            icon: 'success',
            title: data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    } catch (error) {
      if (!error.response.data.ok) {
        return alert(error.response.data.message)
      }
      console.log('error en deletePaquete', error.message);
    }
  }
  //----------------------------------------------------------------------------------------------------
  const actions = async (e) => {
    e.preventDefault();
    edit ? updatePaquete() : savePaquete();
  };
  //----------------------------------------------------------------------------------------------------
  const handleAddRow = () => {
    // Crea una nueva fila de detalle con valores iniciales
    const newDetail = {
      nombredetallesPaqueteTuristico: '',
      precioDetallesPaqueteTuristico: '',
      isAdding: true, // La nueva fila se marca como "agregando"
    };

    // Agrega la nueva fila al estado de detallesPaquetesTuristicos
    setDetallesPaquetesTuristicos([...detallesPaquetesTuristicos, newDetail]);
  };
  //----------------------------------------------------------------------------------------------------


  const handleAddRowCosto = () => {
    // Crea una nueva fila de detalle con valores iniciales
    const newDetail = {
      nombredelcostoPaqueteTuristico: '',
      preciodelcostoPaqueteTuristico: '',
      isAdding: true, // La nueva fila se marca como "agregando"
    };

    // Agrega la nueva fila al estado de detallesPaquetesTuristicos
    setCostosPaqueteTuristico([...costosPaqueteTuristico, newDetail]);
  };
  //----------------------------------------------------------------------------------------------------
  // Función para obtener la lista de destinos desde el servidor
  const obtenerDestinos = async () => {
    try {
      const response = await axios.get('/api/destinos/listall');
      console.log("Lista de destinos:", response);
      const destinosData = response.data.destinos;
      setDestinos(destinosData);
      // Crear un mapeo de _id a nombres
      const nombres = {};
      destinosData.forEach((destino) => {
        nombres[destino._id] = destino.nombreDestino;
      });
      setDestinoNombres(nombres);
    } catch (error) {
      console.error("Error al obtener la lista de destinos:", error);
    }
  };

  //Función para manejar el cambio de destino
  const handleDestinoChange = (destinosId) => {
    setSelectedDestino(destinosId);
  };

  //------------------------------------------------------------------------------------------------------
  // Función para obtener la lista de proveedores desde el servidor
  const obtenerProveedores = async () => {
    try {
      const response = await axios.get('/api/proveedores/listall');
      console.log("Lista de proveedores:", response);

      const proveedoresData = response.data.proveedores;
      setProveedores(proveedoresData);

      // Crear un mapeo de _id a razonsocialProveedor
      const nombreProveedor = {};
      proveedoresData.forEach((proveedor) => {
        nombreProveedor[proveedor._id] = proveedor.razonsocialProveedor;
      });
      setRazonsocialProveedor(nombreProveedor);
    } catch (error) {
      console.error("Error al obtener la lista de proveedores:", error);
    }
  };

  const handleProveedorChange = (proveedorid) => {
    setSelectedProveedor(proveedorid);
  };


  //----------------------------------------------------------------------------------------------------
  const searchFields = [
    'nombrePaqueteTuristico',
    'nombreDestino',
    // Agrega más campos aquí si es necesario
  ];

  const removeAccents = (text) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleSearch = (e) => {
    const searchText = removeAccents(e.target.value.toUpperCase()); // Convertir texto de búsqueda a mayúsculas
    setSearchTerm(searchText);

    const filtered = paquetesturisticos.filter((paquete) => {
      return searchFields.some((field) => {
        // Verifica si el campo actual incluye el término de búsqueda
        let fieldValue = paquete[field];
        if (field === 'nombreDestino') {
          // Si el campo es 'nombreDestino', intenta obtener el nombre del destino del objeto destinoNombres
          fieldValue = destinoNombres[paquete.destinos];
        }
        if (fieldValue) {
          const fieldValueLowerCase = removeAccents(String(fieldValue)).toUpperCase();
          const searchTextLowerCase = removeAccents(searchText).toUpperCase();
          return fieldValueLowerCase.includes(searchTextLowerCase);
        }
        return false;
      });
    });

    setFilteredPaquetes(filtered);
  };

  //----------------------------------------------------------------------------------------------------
  const handleEliminarDetalle = (id) => {
    const updatedDetalles = detallesPaquetesTuristicos.filter((detalle) => detalle._id !== id);
    setDetallesPaquetesTuristicos(updatedDetalles);
  };

  //----------------------------------------------------------------------------------------------------


  const handleEliminarCostos = (index) => {
    const updatedDetalles = costosPaqueteTuristico.filter((_, idx) => idx !== index);
    setCostosPaqueteTuristico(updatedDetalles);
  };


  return (
    <div>
      <div className=" container" style={{ textAlign: 'left' }}>
        <Breadcrumbs />
      </div>
      <div className='container-md mt-5'>
        <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden={!isModalOpen} style={{ display: isModalOpen ? 'block' : 'none' }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header" style={{ backgroundColor: "#008cba" }}>
                  <h5 className="modal-title text-white" id="exampleModalLabel">Ingreso de Paquetes</h5>
                  <button type="button" className="btn-close bg-white" onClick={() => {
                    cleanData(); // Limpia los campos del formulario
                    getData(); // Carga los datos actualizados
                    closeModal();
                  }} title='Cerrar Ventana' />
                </div>
                <div className="modal-body ">
                  <form id=' paquetesForm' onSubmit={actions}>
                    <div className="row container">
                      <div className="col-lg-12 col-sm-12 border border-2 ms-3 mb-2">

                        <div className="col-md-12 col-sm-12 mb-3">
                          <label htmlFor="validationCustom01" className="form-label"><strong>Nombre del Paquete Turístico</strong></label>
                          <input
                            type="text"
                            className="form-control col-12"
                            id="nombrePaqueteTuristico"
                            value={nombrePaqueteTuristico}
                            onChange={(e) => setNombrePaqueteTuristico(e.target.value.toUpperCase())}
                            required
                            disabled={(usuarios.rolUsuario === "Asesor" || usuarios.rolUsuario === "Admin") && edit}
                          />
                        </div>

                        <div className="col-md-12 col-sm-12 mb-3">
                          <label htmlFor="exampleFormControlTextarea1" className="form-label"><strong>Reseña del Paquete Turístico</strong></label>
                          <textarea
                            className="form-control col-12" // Limitar el ancho del textarea en dispositivos móviles
                            id="reseñaPaqueteTuristico"
                            value={reseñaPaqueteTuristico}
                            onChange={(e) => setReseñaPaqueteTuristico(e.target.value)}
                            disabled={(usuarios.rolUsuario === "Asesor" || usuarios.rolUsuario === "Admin") && edit}
                          ></textarea>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-sm-12 mb-3">
                            <label htmlFor="destino" className="form-label"><strong>Destino</strong></label>

                            <select
                              className="form-select"
                              value={selectedDestino}
                              onChange={(e) => handleDestinoChange(e.target.value)}
                              disabled={(usuarios.rolUsuario === "Asesor" || usuarios.rolUsuario === "Admin") && edit}
                            >
                              <option value="">Seleccione destino</option>
                              {Array.isArray(destinos) && destinos.length > 0 ? (
                                destinos
                                  .filter((destino) => destino.estadoDestino === 'Activo') // Filtrar solo los destinos activos
                                  .map((destino) => (
                                    <option key={destino._id} value={destino._id}>
                                      {destino.nombreDestino}
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  Cargando destinos...
                                </option>
                              )}
                            </select>
                          </div>

                          <div className="col-md-6 col-sm-12 mb-3">
                            <label htmlFor="proveedores" className="form-label"><strong>Proveedor:</strong></label>
                            <select
                              className="form-select"
                              value={selectedProveedor}
                              onChange={(e) => handleProveedorChange(e.target.value)}
                              disabled={(usuarios.rolUsuario === "Asesor" || usuarios.rolUsuario === "Admin") && edit}
                            >
                              <option value="">Seleccione proveedor</option>
                              {Array.isArray(proveedores) && proveedores.length > 0 ? (
                                proveedores.map((proveedor) => (
                                  <option key={proveedor._id} value={proveedor._id}>
                                    {proveedor.razonsocialProveedor}
                                  </option>
                                ))
                              ) : (
                                <option value="" disabled>
                                  Cargando proveedore...
                                </option>
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-sm-12 mb-3">
                            <label htmlFor="categoriaPaqueteTuristico" className="form-label">
                              <strong>Categoría</strong>
                            </label>

                            <select
                              className="form-select"
                              id="categoriaPaqueteTuristico"
                              name="categoriaPaqueteTuristico"
                              value={categoriaPaqueteTuristico}
                              onChange={(e) => setCategoriaPaqueteTuristico(e.target.value)}
                              required
                              disabled={usuarios.rolUsuario !== 'Admin' && usuarios.rolUsuario !== 'SuperAdmin'}
                            >
                              <option value="Seleccione categoria">Seleccione categoria</option>
                              <option value="CULTURAL">CULTURAL</option>
                              <option value="AVENTURA">AVENTURA</option>
                              <option value="ECOTURISMO">ECOTURISMO</option>
                              <option value="SOL Y PLAYA">SOL Y PLAYA</option>
                              <option value="SALUD Y BIENESTAR">SALUD Y BIENESTAR</option>
                              <option value="GASTRONÓMICO">GASTRONÓMICO</option>
                              <option value="RELIGIOSO O ESPIRITUAL">RELIGIOSO O ESPIRITUAL</option>
                              <option value="DEPORTIVO">DEPORTIVO</option>
                              <option value="DE COMPRAS">DE COMPRAS</option>
                              <option value="DE NEGOCIOS">DE NEGOCIOS</option>
                              <option value="RURAL">RURAL</option>
                              <option value="DE CRUCEROS">DE CRUCEROS</option>
                              <option value="DE LUJO">DE LUJO</option>
                              <option value="NATURALEZA">NATURALEZA</option>
                              <option value="SOSTENIBLE">SOSTENIBLE</option>
                              <option value="CIENTÍFICO">CIENTÍFICO</option>
                              <option value="IDIOMÁTICO">IDIOMÁTICO</option>
                              <option value="INDUSTRIAL">INDUSTRIAL</option>
                              <option value="LITERARIO">LITERARIO</option>
                            </select>
                          </div>

                          <div className="col-md-6 col-sm-12 mb-3">
                            <label htmlFor="estadoPaqueteTuristico" className="form-label"><strong>Estado</strong></label>

                            <select
                              className="form-select"
                              id="estadoPaqueteTuristico"
                              name="estadoPaqueteTuristico"
                              value={estadoPaqueteTuristico}
                              onChange={(e) => setEstadoPaqueteTuristico(e.target.value)}
                              required
                              disabled={usuarios.rolUsuario !== 'Admin' && usuarios.rolUsuario !== 'SuperAdmin'}
                            >
                              <option value="Activo">Activo</option>
                              <option value="Inactivo">Inactivo</option>
                            </select>
                          </div>
                        </div>

                        <div className="mt-2 mb-2">
                          {(usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin') && (
                            <button
                              type="button"
                              className="btn btn-success w-100"
                              onClick={handleAddRowCosto}
                              title='Agregar Costo'
                            >
                              Agregar costos del Paquete Turistico
                            </button>
                          )}
                        </div>

                        <div className="col-md-12">
                          <div className="table-responsive overflow-auto"> {/* Agregar clases para permitir el desplazamiento horizontal */}
                            <table className="table table-hover mt-2">
                              <thead>
                                <tr>
                                  <th>Detalle del Costo</th>
                                  <th>Precio</th>
                                  <th scope="col">Acción</th>
                                </tr>
                              </thead>
                              <tbody>
                                {costosPaqueteTuristico.map((detalle, index) => (
                                  <tr key={`${detalle._id}-${index}`}>
                                    <td>
                                      <input
                                        name={`costo${index}`}
                                        type="text"
                                        className="form-control"
                                        id={`nombrecostodelPaqueteTuristico${index}`}
                                        placeholder="Nombre del costo"
                                        value={detalle.nombredelcostoPaqueteTuristico}
                                        onChange={(e) => {
                                          const updatedDetalles = [...costosPaqueteTuristico];
                                          updatedDetalles[index].nombredelcostoPaqueteTuristico = e.target.value.toUpperCase();
                                          setCostosPaqueteTuristico(updatedDetalles);
                                        }}
                                        disabled={usuarios.rolUsuario !== 'Admin' && usuarios.rolUsuario !== 'SuperAdmin'}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name={`valordelcosto${index}`}
                                        type="text"
                                        className="form-control text-end"
                                        id={`valorcostodelPaqueteTuristico${index}`}
                                        placeholder="Precio"
                                        value={detalle.preciodelcostoPaqueteTuristico}
                                        onChange={(e) => {
                                          const updatedDetalles = [...costosPaqueteTuristico];
                                          updatedDetalles[index].preciodelcostoPaqueteTuristico = e.target.value;
                                          setCostosPaqueteTuristico(updatedDetalles);
                                        }}
                                        disabled={usuarios.rolUsuario !== 'Admin' && usuarios.rolUsuario !== 'SuperAdmin'}
                                      />
                                    </td>
                                    <td>
                                      <div className="btn-group btn-group-sm" role="group">
                                        <>
                                          {usuarios.rolUsuario === 'SuperAdmin' && (
                                            <button
                                              className='btn btn-danger me-2'
                                              onClick={() => handleEliminarCostos(index)}
                                              title='Eliminar Costos'
                                            >
                                              <i className="fa-solid fa-trash"></i>
                                            </button>
                                          )}
                                        </>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="modal-footer border-5 justify-content-center">
                          <button
                            type="button"
                            className="btn btn-danger "
                            onClick={() => {
                              getData(); // Carga los datos actualizados
                              cleanData(); // Limpia los campos del formulario
                              closeModal();
                              document.getElementById("paquetesForm"); // Cierra el modal
                            }}
                            data-bs-dismiss="modal" title='Cerrar Ventana'
                          >
                            Cerrar
                          </button>
                          {(usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin') && (
                            <button
                              type="submit"
                              className="btn btn-primary"
                              title='Guardar Paquete'
                            >
                              Guardar Registro
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12 col-sm-12 border border-2 ms-3 mb-2">
                        <div>
                          {(usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin') && (
                            <button
                              type="button"
                              className="btn btn-success w-100 mt-2"
                              onClick={handleAddRow}
                              title='Agregar Detalle'
                            >
                              Agregar Detalles
                            </button>
                          )}
                        </div>

                        <table className="table table-hover mt-2">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Detalle de Paquete</th>
                              <th>Precio</th>
                              <th scope="col">Acción</th>
                            </tr>
                          </thead>
                          <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {detallesPaquetesTuristicos.map((detail, index) => (
                              <tr key={`${detail._id}-${index}`}>
                                <td>{index + 1}</td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="nombredetallesPaqueteTuristico"
                                    value={detail.nombredetallesPaqueteTuristico}
                                    onChange={(e) => {
                                      const updatedDetalles = [...detallesPaquetesTuristicos];
                                      updatedDetalles[index].nombredetallesPaqueteTuristico = e.target.value;
                                      setDetallesPaquetesTuristicos(updatedDetalles);
                                    }}
                                    disabled={usuarios.rolUsuario !== 'Admin' && usuarios.rolUsuario !== 'SuperAdmin'}
                                  />
                                </td>
                                <td className="col-3">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    name="precioDetallesPaqueteTuristico"
                                    value={detail.precioDetallesPaqueteTuristico}
                                    onChange={(e) => {
                                      const updatedDetalles = [...detallesPaquetesTuristicos];
                                      updatedDetalles[index].precioDetallesPaqueteTuristico = e.target.value;
                                      setDetallesPaquetesTuristicos(updatedDetalles);
                                    }}
                                    disabled={usuarios.rolUsuario !== 'Admin' && usuarios.rolUsuario !== 'SuperAdmin'}
                                    placeholder="Precio"
                                  />
                                </td>
                                <td>
                                  <div className="btn-group btn-group-sm" role="group">
                                    <>
                                      {usuarios.rolUsuario === 'SuperAdmin' && (
                                        <span
                                          className='btn btn-danger me-2'
                                          onClick={() => handleEliminarDetalle(detail._id)}
                                          title='Eliminar Detalle'
                                        >
                                          <i className="fa-solid fa-trash"></i>
                                        </span>
                                      )}
                                    </>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>




                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Inicio de la tabla de Clientes*/}
      < div className='container container-flex card Larger shadow mt-3' >
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="dropdown no-arrow align-items-center">
            <button className="btn btn-link btn-sm dropdown-toggle" aria-expanded="false" data-bs-toggle="dropdown" type="button">
              <i className="fas fa-ellipsis-v text-gray-400"></i>
            </button>
            <div className="dropdown-menu shadow dropdown-menu-end animated--fade-in">
              <label className="text-center dropdown-header">Exportar:</label>
              <Link className="dropdown-item" href="#">
                <i className="fa-solid fa-file-pdf me-2"></i>Pdf
              </Link>
              <Link className="dropdown-item" href="#">
                <i className="fa-solid fa-file-excel me-2"></i> Excel
              </Link>
            </div>
          </div>
          <div>
            <h6 className="text-primary fw-bold m-0 mt-1 text-start">Lista de Paquetes</h6>
          </div>
          <div>
            {(usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin') && (
              <button type="button" className="btn btn-primary rounded-circle aling-end ms-2" style={{ backgroundColor: "#008cba" }} onClick={() => {
                setIsModalOpen(true); // Abre la modal al hacer clic
              }} title="Haga clic para agregar un nuevo paquete">< i className="fa-solid fa-plus fa-beat "></i></button>
            )}
          </div>
        </div>
        <div className='mb-2'>
          <div className="input-group">
            <span className="input-group-text">
              <i className="fa-solid fa-search"></i>
            </span>
            <input className="form-control" aria-label="Search"
              type="text"
              placeholder="Buscar paquete..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>

        <div className="my-1 d-flex justify-content-end mb-3 border-5">
          <Pagination
            className='pagination'
            current={page}
            total={totalPages}
            pageSize={1}
            onChange={onChangePage}
          />
        </div>

        {/* Mostrar tabla solo en dispositivos grandes (computadoras) */}
        <div className='d-none d-md-block'>
          <div className="table-responsive">
            <table className='table table-bordered border-1 table-hover mt-2'>
              {/* ... contenido de la tabla ... */}
              <thead>
                <tr style={{ background: "#008cba", color: "#ffffff" }}>
                  <th scope="col" className="responsive-text" >#</th>
                  <th scope="col" className="responsive-text text-truncate">Nombre Paquete</th>
                  <th scope="col" className="responsive-text">Reseña del Paquete</th>
                  <th scope="col" className="responsive-text text-truncate">Destino</th>
                  <th scope="col" className='responsive-text'>Valor/Precio</th>
                  <th scope="col" className='responsive-text'>Proveedor</th>
                  <th scope="col" className="responsive-text">Estado</th>

                  <th scope="col" className="responsive-text">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(filteredPaquetes) && filteredPaquetes.map((item, i) => (
                  <tr key={item._id}>
                    <td className="responsive-text">{i + 1}</td>
                    <td className="responsive-text">{item.nombrePaqueteTuristico}</td>
                    <td className="responsive-text">{item.reseñaPaqueteTuristico}</td>
                    <td className="responsive-text">{destinoNombres[item.destinos]}</td>
                    <td className="responsive-text">
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <table>
                          <tbody>
                            {item.costosPaqueteTuristico.map((costo, index) => (
                              <tr key={index}>
                                <td style={{ whiteSpace: 'nowrap' }}>{costo.nombredelcostoPaqueteTuristico}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{costo.preciodelcostoPaqueteTuristico ? costo.preciodelcostoPaqueteTuristico.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : ''}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </td>
                    <td className="responsive-text">{razonsocialProveedor[item.proveedores]}</td>
                    <td>
                      <div className="btn-group btn-group-sm" role="group">
                        <span>
                          {item.estadoPaqueteTuristico === 'Activo' ? (
                            <i className="fa-solid fa-square-check fa-2xl" style={{ color: '#198754' }}></i>
                          ) : (
                            <i className="fa-solid fa-square-xmark fa-2xl" style={{ color: '#dc3545' }}></i>
                          )}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="btn-group btn-group-sm" role="group">
                        <>
                          {(usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin') && (
                            <span className='btn btn-primary d-flex align-items-center me-2' onClick={() => editData(item)} title='Editar'>
                              <i className="fa-solid fa-pencil space-i"></i>
                            </span>
                          )}

                          {usuarios.rolUsuario === 'Asesor' && (
                            <span className='btn d-flex align-items-center me-2' onClick={() => editData(item)} title='Ver más'>
                              <i className="fa-solid fa-eye space-i fa-2xl"></i>
                            </span>
                          )}


                          {usuarios.rolUsuario === 'SuperAdmin' && (
                            <span className='btn btn-danger me-2' onClick={() => deletePaquete(item._id)} title='Eliminar'>
                              <i className="fa-solid fa-trash"></i>
                            </span>
                          )}
                        </>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Mostrar tarjetas solo en dispositivos pequeños (móviles) */}
        <div className='d-md-none'>
          {Array.isArray(paquetesturisticos) && paquetesturisticos.map((item, i) => (
            <div key={item._id} className='card border-3 mt-2'>
              {/* Contenido de la tarjeta */}
              <div className='card-body'>
                <h5 className='card-title'>Paquete {i + 1}</h5>
                <p className='card-text'>
                  <strong>Nombre paquete:</strong> {item.nombrePaqueteTuristico}<br />
                  <strong>Reseña del Paquete:</strong> {item.reseñaPaqueteTuristico}<br />
                  <strong>Destino:</strong> {destinoNombres[item.destinos]}<br />
                  <strong>Valor/Precio</strong>
                  {item.costosPaqueteTuristico.map((costo, index) => (
                    <div key={index}>
                      <div>
                        {costo.nombredelcostoPaqueteTuristico}: {costo.preciodelcostoPaqueteTuristico ? costo.preciodelcostoPaqueteTuristico.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : ''}
                      </div>
                    </div>
                  ))}

                </p>
                <div className='btn-group btn-group-xl d-flex'>
                  <>
                    {(usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin') && (
                      <span className='btn btn-primary d-flex align-items-center me-2 col-6' onClick={() => editData(item)} title='Editar'>
                        <i className="fa-solid fa-pencil space-i"></i>
                        <span className="m-auto">Editar</span>
                      </span>
                    )}

                    {usuarios.rolUsuario === 'Asesor' && (
                      <span className='btn btn-dark d-flex align-items-center me-2 col-6 text-item-center' onClick={() => editData(item)} title='Ver más'>
                        <i className="fa-solid fa-eye space-i fa-2xl"></i>
                        <span className="ms-auto">Ver Paquete</span>
                      </span>
                    )}
                  </>


                  <span className='btn d-flex align-items-center'>
                    {item.estadoPaqueteTuristico === 'Activo' ? (
                      <i className="fa-solid fa-square-check fa-2xl" style={{ color: '#198754' }}></i>
                    ) : (
                      <i className="fa-solid fa-square-xmark fa-2xl" style={{ color: '#dc3545' }}></i>
                    )}
                  </span>
                  <>
                    {usuarios.rolUsuario === 'SuperAdmin' && (
                      <span className='btn btn-danger d-flex align-items-center me-2 col-6' onClick={() => deletePaquete(item._id)} title='Eliminar'>
                        <i className="fa-solid fa-trash"></i>
                        <span className="ms-auto">Eliminar</span>
                      </span>
                    )}
                  </>
                </div>

              </div>
            </div>
          ))}
        </div>
        <div className="my-1 d-flex justify-content-end mb-3 border-5">
          <Pagination
            className='pagination'
            current={page}
            total={totalPages}
            pageSize={1}
            onChange={onChangePage}
          />
        </div>
      </div >
      {/* Fin de la tabla de Clientes*/}
    </div >

  )
}

export default Paquetes