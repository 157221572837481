import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs ';
import { useUsuarios } from '../context/UsuariosContext';
import CalendarioReseravas from './CalendarioResedrvas';


const Home = () => {

  const { usuarios } = useUsuarios();
  const options = { headers: { authorization: "Bearer " + usuarios.token }, };
  
  const [totalClientes, setTotalClientes] = useState(0);
  /* const [totalDestinos, setTotalDestinos] = useState(0); */
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [totalReservas, setTotalReservas] = useState(0);
  const [totalCotizaciones, setTotalCotizaciones] = useState(0);


  // Función para realizar la solicitud HTTP
  const fetchTotalClientes = () => {
    axios.get('/api/clientes/listall', options)
      .then(response => {
        const data = response.data;
        if (data.ok) {
          setTotalClientes(data.totalClientes);
        } else {
          console.error('Error en la solicitud al servidor: ', data.message);
        }
      })
      .catch(error => {
        console.error('Error en la solicitud: ', error);
      });
  };


  // Función para obtener el total de destinos
  /* const fetchTotalDestinos = () => {
    axios.get('/api/destinos/listAll')
      .then(response => {
        const data = response.data;
        if (data.ok) {
          setTotalDestinos(data.totalDestinos);
        } else {
          console.error('Error en la solicitud al servidor: ', data.message);
        }
      })
      .catch(error => {
        console.error('Error en la solicitud: ', error);
      });
  }; */

  // Función para obtener el total de reservas
  const fetchTotalReservas = () => {
    axios.get('/api/reservas/listall', options)
      .then(response => {
        const data = response.data;
        if (data.ok) {
          // Extrae los valores de la respuesta
          setTotalRegistros(data.totalRegistros);
          setTotalReservas(data.totalReservas);
          setTotalCotizaciones(data.totalCotizaciones);
        } else {
          console.error('Error en la solicitud al servidor: ', data.message);
        }
      })
      .catch(error => {
        console.error('Error en la solicitud: ', error);
      });
  };


  // Llama a fetchTotalClientes cuando el componente se monta
  useEffect(() => {
    fetchTotalClientes();
    /* fetchTotalDestinos(); */
    fetchTotalReservas();
    fetchRandomDestinos();
    obtenerDestinos();
    fetchReservas();
    fetchClientesByCurrentMonth();
  }, []);

  const [reservas, setReservas] = useState([]);
  const [destinoNombres, setDestinoNombres] = useState([]);

  const obtenerDestinos = async () => {
    try {
      const response = await axios.get('/api/destinos/listall', options); // Reemplaza la URL con la correcta
      const destinosData = response.data.destinos;
      const nombres = {};
      destinosData.forEach((destino) => {
        nombres[destino._id] = destino.nombreDestino;
      });
      setDestinoNombres(nombres);
    } catch (error) {
      console.error('Error al obtener la lista de destinos:', error);
    }
  };

  // Función para obtener las reservas desde el servidor
  const fetchReservas = () => {
    axios.get('/api/reservas/listbydata', options)
      .then(response => {
        const data = response.data;
        if (data.ok) {
          setReservas(data.reservas);
        } else {
          console.error('Error en la solicitud al servidor: ', data.message);
        }
      })
      .catch(error => {
        console.error('Error en la solicitud: ', error);
      });
  };


  //----------------------------------------------------------------

  const [destinos, setDestinos] = useState([]);

  /// Función para obtener 10 destinos aleatorios
  const fetchRandomDestinos = async () => {
    try {
      const response = await axios.get('/api/destinos/listall', options);
      const data = response.data;
      if (data.ok) {
        const allDestinos = data.destinos;
        const firstTenDestinos = allDestinos.slice(0, 10); // Obtén los primeros 10 destinos
        setDestinos(firstTenDestinos);
      } else {
        console.error('Error en la solicitud al servidor: ', data.message);
      }
    } catch (error) {
      console.error('Error en la solicitud: ', error);
    }
  };



  const [clientes, setClientes] = useState([]);

  // Función para obtener clientes cuyas fechas de nacimiento están en el mes actual
  const fetchClientesByCurrentMonth = async () => {
    try {
      const response = await axios.get('/api/clientes/current-month', options);
      const data = response.data;
      if (data.ok) {
        setClientes(data.clientes);
      } else {
        console.error('Error en la solicitud al servidor: ', data.message);
      }
    } catch (error) {
      console.error('Error en la solicitud: ', error);
    }
  };

  //----------------------------------------------------------------------------------------------

  const AlertadeViaje = ({ fechaSalida }) => {
    // Obtener la fecha actual en formato YYYY-MM-DD
    const currentDate = new Date();
    const currentDateFormatted = currentDate.toISOString().slice(0, 10);

    // Convertir fechaSalida a formato YYYY-MM-DD
    const fechaSalidaFormatted = new Date(fechaSalida);
    const fechaSalidaFormattedString = fechaSalidaFormatted.toISOString().slice(0, 10);

    let iconColor;

    // Calcular la diferencia en días entre la fecha actual y la fecha de salida
    const diffInDays = Math.ceil((fechaSalidaFormatted - currentDate) / (1000 * 60 * 60 * 24));

    // Establecer las condiciones en función de la diferencia en días
    if (diffInDays === 2 || diffInDays === 1) {
      iconColor = '#F1C40F'; // Faltan dos días o un día, color amarillo
    } else if (currentDateFormatted === fechaSalidaFormattedString) {
      iconColor = '#145A32'; // Fecha actual, color verde
    } else if (currentDate > fechaSalidaFormatted) {
      iconColor = '#A93226'; // Fecha pasada, color rojo
    } else {
      iconColor = '#FFFFFF'; // Blanco para cualquier otro caso
    }

    // Retornar el icono
    return (
      <i className="fa-solid fa-circle fa-xl" style={{ color: iconColor }}></i>
    );
  }


  //---------------------------------------------------------------------------------------------

  const sortedReservas = reservas.slice().sort((a, b) => {
    // Convierte las fechas en objetos Date para compararlas
    const dateA = new Date(a.fechaSalida);
    const dateB = new Date(b.fechaSalida);

    // Compara las fechas y devuelve el resultado adecuado para ordenarlas
    if (dateA > dateB) {
      return -1; // Indica que 'a' debería ir antes que 'b'
    } else if (dateA < dateB) {
      return 1; // Indica que 'b' debería ir antes que 'a'
    } else {
      return 0; // Indica que las fechas son iguales
    }
  });

  //---------------------------------------------------------------------------------------------

  return (
    <div>
      <div className=" container" style={{ textAlign: 'left' }}>
        <Breadcrumbs />
      </div>

      <div className='container-md mt-5'>

        <div className="row">
          {/* Card 1 */}
          <div className="col-lg-3 col-6 mb-4">
            <div className="card text-white bg-info">
              <div className="card-body">
                <h3 className="card-title">{totalClientes}</h3>
                <p className="card-text">Total Clientes</p>
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <Link className="text-white">Más Info</Link>
                <i className="fas fa-arrow-circle-right"></i>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-lg-3 col-6 mb-4">
            <div className="card text-white bg-success">
              <div className="card-body">
                <h3 className="card-title">{totalReservas}</h3>
                <p className="card-text">Total Reservas</p>
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <Link className="text-white">Más Info</Link>
                <i className="fas fa-arrow-circle-right"></i>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-lg-3 col-6 mb-4">
            <div className="card text-white bg-warning">
              <div className="card-body">
                <h3 className="card-title">{totalRegistros}</h3>
                <p className="card-text">Total Registros</p>
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <Link className="text-white">Más Info</Link>
                <i className="fas fa-arrow-circle-right"></i>
              </div>
            </div>
          </div>

          {/* Card 4 */}
          <div className="col-lg-3 col-6 mb-4">
            <div className="card text-white bg-danger">
              <div className="card-body">
                <h3 className="card-title">{totalCotizaciones}</h3>
                <p className="card-text">Total Cotizaciones</p>
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <Link className="text-white">Más Info</Link>
                <i className="fas fa-arrow-circle-right"></i>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card shadow" >
              <div className="card-body">

                <div className="card container text-center mb-2">
                  <div className="row align-items-center mt-3 mb-3">
                    <div className="col-1 d-flex align-items-center justify-content-start">
                      <i className="fa-solid fa-users fa-2x"></i>
                    </div>
                    <div className="col-10 d-flex align-items-center justify-content-center">
                      <h5><strong>Nuestros Clientes</strong></h5>
                    </div>
                  </div>
                </div>

                <div className="card container text-center">
                  <h5>Cumpleañeros del Mes</h5>
                  <div className="row align-items-center justify-content-center">
                    <ul>
                      {clientes.map((cliente) => (
                        <ol key={cliente._id}>
                          {cliente.nombre1Cliente} {cliente.apellido1Cliente} - {cliente.fechanacimientoCliente.slice(0, 10)}
                        </ol>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card shadow" >
              <div className="card-body">

                <div className="card container text-center mb-2">
                  <div className="row align-items-center mt-3 mb-3">
                    <div className="col-1 d-flex align-items-center justify-content-start">
                      <i className="fa-solid fa-plane fa-2xl"></i>
                    </div>
                    <div className="col-10 d-flex align-items-center justify-content-center">
                      <h5><strong>Total destinos</strong></h5>
                    </div>
                  </div>
                </div>

                <div className="card container text-center mb-2">
                  <h5>Nuestros destinos</h5>
                  <div className="row align-items-center justify-content-center">
                    <div className="col-12 d-flex justify-content-center">
                      <ul className="list-unstyled">
                        {destinos.map((destino, index) => (
                          <li key={index} className="text-center">
                            {destino.nombreDestino}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div className="col-md-4 mb-4">
            <div className="card shadow" >
              <div className="card-body">

                <div className="card container text-center mb-2">
                  <div className="row align-items-center mt-3 mb-3">
                    <div className="col-1 d-flex align-items-center">
                      <i className="fa-solid fa-hotel fa-2xl"></i>
                    </div>
                    <div className="col-10 d-flex align-items-center justify-content-center">
                      <h5><strong>Reservas activas</strong></h5>
                    </div>
                  </div>
                </div>

                <div className="card container text-center">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-6 col-md-3 d-flex justify-content-end">
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Fecha de Salida</th>
                          <th>Alerta</th>
                          <th>Destino</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedReservas.map((reserva, index) => (
                          <tr key={index}>
                            <td>{reserva.fechaSalida.slice(0, 10)}</td>
                            <td>
                              <AlertadeViaje fechaSalida={reserva.fechaSalida.slice(0, 10)} />
                            </td>
                            <td>
                              {reserva.destinos.map((destinoId, dIndex) => (
                                <span key={dIndex}>
                                  {destinoNombres[destinoId] || 'Cargando...'}
                                  {dIndex < reserva.destinos.length - 1 ? ', ' : ''}
                                </span>
                              ))}
                            </td>
                            
                          </tr>
                          
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>

          <CalendarioReseravas/>

        </div>

      </div>
    </div>


  )
}

export default Home