import axios from 'axios';
import moment from 'moment';
import 'moment-timezone'; // Importa moment-timezone
import 'moment/locale/es'; // Importa el locale en español para moment
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Importa los estilos del calendario
import { useUsuarios } from '../context/UsuariosContext';
import { locales } from './Es'; // Importa las traducciones

// Configura el localizador con moment
const localizer = momentLocalizer(moment);

// Personaliza los textos de los botones y otros elementos del calendario
const messages = {
    allDay: locales.allDay,
    previous: locales.previous,
    next: locales.next,
    today: locales.today,
    month: locales.month.long,
    week: locales.week.long,
    day: locales.day.long,
    agenda: locales.agenda.long,
    noEvents: locales.noEvents,
};

// Componente para mostrar el icono de alerta basado en la fecha de salida
const AlertadeViaje = ({ fechaSalida }) => {
    const currentDate = new Date();
    const currentDateFormatted = currentDate.toISOString().slice(0, 10);

    const fechaSalidaFormatted = new Date(fechaSalida);
    const fechaSalidaFormattedString = fechaSalidaFormatted.toISOString().slice(0, 10);

    let iconColor;

    const diffInDays = Math.ceil((fechaSalidaFormatted - currentDate) / (1000 * 60 * 60 * 24));

    if (diffInDays === 2 || diffInDays === 1) {
        iconColor = '#F1C40F'; // Faltan dos días o un día, color amarillo
    } else if (currentDateFormatted === fechaSalidaFormattedString) {
        iconColor = '#145A32'; // Fecha actual, color verde
    } else if (currentDate > fechaSalidaFormatted) {
        iconColor = '#A93226'; // Fecha pasada, color rojo
    } else {
        iconColor = '#FFFFFF'; // Blanco para cualquier otro caso
    }

    return (
        <i className="fa-solid fa-circle fa-xl" style={{ color: iconColor }}></i>
    );
};

const CalendarioReservas = () => {

    const { usuarios } = useUsuarios();
    const options = { headers: { authorization: "Bearer " + usuarios.token }, };


    const [reservas, setReservas] = useState([]);
    const [destinoNombres, setDestinoNombres] = useState({});

    useEffect(() => {
        // Función para obtener destinos
        const obtenerDestinos = async () => {
            try {
                const response = await axios.get('/api/destinos/listall', options);
                const destinosData = response.data.destinos;
                const nombres = {};
                destinosData.forEach((destino) => {
                    nombres[destino._id] = destino.nombreDestino;
                });
                setDestinoNombres(nombres);
            } catch (error) {
                console.error('Error al obtener la lista de destinos:', error);
            }
        };

        // Función para obtener reservas
        const fetchReservas = () => {
            axios.get('/api/reservas/listbydata', options)
                .then(response => {
                    const data = response.data;
                    if (data.ok) {
                        setReservas(data.reservas);
                    } else {
                        console.error('Error en la solicitud al servidor: ', data.message);
                    }
                })
                .catch(error => {
                    console.error('Error en la solicitud: ', error);
                });
        };

        obtenerDestinos();
        fetchReservas();
    }, []);

    // Ordenar las reservas por fecha de salida
    const sortedReservas = reservas.slice().sort((a, b) => {
        const dateA = moment.utc(a.fechaSalida).tz('America/Bogota').toDate(); // Ajusta la fecha a la zona horaria de Colombia
        const dateB = moment.utc(b.fechaSalida).tz('America/Bogota').toDate(); // Ajusta la fecha a la zona horaria de Colombia

        return dateB - dateA; // Orden descendente
    });

    // Convierte las reservas a eventos para el calendario
    const eventos = sortedReservas.map(reserva => {
        const fechaSalida = moment.utc(reserva.fechaSalida).tz('America/Bogota').toDate(); // Ajusta la fecha a la zona horaria de Colombia
        const fechaLlegada = moment.utc(reserva.fechaLlegada).tz('America/Bogota').toDate(); // Ajusta la fecha a la zona horaria de Colombia

        return {
            title: reserva.destinos.map(destinoId => destinoNombres[destinoId] || 'Cargando...').join(', '), // Mostrar destinos como título del evento
            start: fechaSalida,
            end: fechaLlegada,
            tooltip: `
                Reserva: ${reserva.numeroReserva}
                Fecha de Salida: ${fechaSalida.toLocaleDateString('es-CO')}
                Fecha de Llegada: ${fechaLlegada.toLocaleDateString('es-CO')}
                Destinos: ${reserva.destinos.map(destinoId => destinoNombres[destinoId] || 'Cargando...').join(', ')}
                Pasajeros: ${reserva.npasajeros}
            `, // Información adicional para el tooltip
            fechaSalida: reserva.fechaSalida.slice(0, 10), // Para pasar al componente AlertadeViaje
            numeroReserva: reserva.numeroReserva // Incluye el numeroReserva en los eventos
        };
    });

    return (
        <div className="container mt-5">
            <h2>Calendario de Reservas</h2>
            <div className="calendar-container">
                <Calendar
                    localizer={localizer}
                    events={eventos}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600 }}
                    views={['month', 'week', 'day', 'agenda']}
                    messages={messages}
                    eventPropGetter={(event) => ({
                        title: event.title,
                        tooltip: event.tooltip, // Aplica el tooltip
                    })}
                    components={{
                        event: ({ event }) => (
                            <div
                                className="rbc-event-content d-flex justify-content-between align-items-center"
                                title={event.tooltip} // Usa el tooltip aquí
                            >
                                <span>{event.title}</span>
                                <span className="ms-2">{event.numeroReserva}</span> {/* Muestra el numeroReserva */}
                                <AlertadeViaje fechaSalida={event.fechaSalida} />
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default CalendarioReservas;
