import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../Images/Footer.png';
import LogoColor from '../Images/LogoColor.png';
import { useUsuarios } from '../context/UsuariosContext';


// Definir estilos
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 0,
    },
    marginContainer: {
        borderWidth: 0,
        borderColor: 'black',
        margin: 30,
        padding: 10,
    },
    header: {
        flexDirection: 'row',
        width: '100%',
        marginBottom: 2,
        height: 80,
        borderWidth: 2,
        borderColor: '#ccc', // Color gris claro
    },
    titleContainer: {
        flex: 1, // Para que cada columna ocupe el mismo espacio
        justifyContent: 'center', // Para centrar verticalmente los elementos
        alignItems: 'center', // Para centrar horizontalmente los elementos
    },
    title: {
        fontWeight: 'bold',
        fontSize: 14,

    },
    subtitle: {
        fontSize: 10,
    },
    label: {
        fontSize: 12,
    },
    logo: {
        width: 170, // Ajusta el tamaño del logo según tus necesidades
        height: 70, // Ajusta el tamaño del logo según tus necesidades
    },

    content: {
        marginTop: 10, // Ajusta el margen superior para dejar espacio al encabezado
        marginBottom: 10, // Ajusta el margen inferior para dejar espacio al pie de página
        borderWidth: 2,
        borderColor: '#ccc', // Color gris claro
    },

    contentPasajeros: {
        marginTop: 5, // Ajusta el margen superior para dejar espacio al encabezado
        marginBottom: 20, // Ajusta el margen inferior para dejar espacio al pie de página
        borderWidth: 2,
        borderColor: '#ccc', // Color gris claro
    },

    section: {
        margin: 10,
        padding: 4,
        width: '95%',
    },
    sectionTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        borderBottomWidth: 0.5,
        backgroundColor: '#ccc',
        borderBottomColor: 'black',
        marginBottom: 10, // Espacio después del subtítulo
        width: '100%', // Asegura que el subtítulo ocupe todo el ancho disponible
    },
    table: {
        width: "100%",
        borderStyle: 0,
        borderWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        width: "100%",
        flexDirection: "row",
        borderBottomWidth: 0,
        borderBottomColor: "#000"
    },
    text: {
        fontSize: 10
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        height: 120,
        borderWidth: 2,
        borderColor: '#ccc', // Color gris claro
    },

});


// Componente MyDocument
const MyDocument = ({ reserva }) => (
    <Document>
        <Page size="A4" style={styles.page} wrap>
            <View style={styles.marginContainer}>
                {/* -------------------------------- Encabezado -------------------------------- */}
                <View style={styles.header}>
                    {/* Primera columna para el logo */}
                    <View style={[styles.titleContainer, { flex: 3, textAlign: 'center' }]} >
                        <Image src={LogoColor} style={styles.logo} />
                    </View>
                    {/* Segunda columna para el título y el subtítulo */}
                    <View style={[styles.titleContainer, { flex: 5, textAlign: 'center' }]} >
                        <Text style={styles.title}>Agencia Jukumaritravels</Text>
                        <Text style={styles.subtitle}>RNT 138002 - NIT 71227531-3</Text>
                    </View>
                    {/* Tercera columna para la información de la reserva */}
                    <View style={[styles.titleContainer, { flex: 3, textAlign: 'center' }]}>
                        <Text style={styles.title}>{reserva.tipoDocumento}</Text>
                        <Text style={styles.subtitle}>
                            {reserva.tipoDocumento === 'COTIZACIÓN'
                                ? `COTI${formatNumeroRecibo(reserva.numeroCotizacion)}`
                                : `RES${formatNumeroRecibo(reserva.numeroReserva)}`}
                        </Text>
                    </View>
                </View>

                {/* -------------------------------- Contenido principal -------------------------------- */}

                {/* -------------------------------- Informacion del cliente -------------------------------- */}
                <View style={styles.content}>
                    <Text style={styles.sectionTitle}>Información Cliente</Text>
                    {/* Información Cliente */}
                    <Text style={styles.text}> Nomrbe del Cliente: {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                        reserva.clientes.map(cliente => {
                            // Filtra los valores nulos o vacíos
                            const nombres = [cliente.nombre1Cliente, cliente.nombre2Cliente].filter(Boolean).join(' ');
                            const apellidos = [cliente.apellido1Cliente, cliente.apellido2Cliente].filter(Boolean).join(' ');
                            // Concatena nombres y apellidos solo si ambos están presentes
                            return nombres && apellidos ? `${nombres} ${apellidos}` : null;
                        }).filter(Boolean).join(', ')
                    ) : (
                        'No hay clientes'
                    )}</Text>

                    <View style={styles.section}>
                        <View style={styles.table}>
                            {/* Encabezado de la tabla */}
                            <View style={styles.tableRow}>
                                <View style={[styles.cell, { flex: 2, alignItems: 'center' }]}><Text style={styles.text}>Documento:</Text></View>
                                <View style={[styles.cell, { flex: 2, alignItems: 'center' }]}><Text style={styles.text}>Telefono:</Text></View>
                                <View style={[styles.cell, { flex: 2, alignItems: 'center' }]}><Text style={styles.text}>Email:</Text></View>
                            </View>

                            {/* Contenido de la tabla */}
                            {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                reserva.clientes.map(cliente => (
                                    <View key={cliente._id} style={styles.tableRow}>
                                        <View style={[styles.cell, { flex: 2, alignItems: 'center' }]}><Text style={styles.text}>{cliente.documentoCliente}</Text></View>
                                        <View style={[styles.cell, { flex: 2, alignItems: 'center' }]}><Text style={styles.text}>{cliente.telefono1Cliente}</Text></View>
                                        <View style={[styles.cell, { flex: 2, alignItems: 'center' }]}><Text style={styles.text}>{cliente.emailCliente}</Text></View>
                                    </View>
                                ))
                            ) : (
                                <View style={styles.tableRow}>
                                    <View style={styles.cell}><Text style={styles.text}>No hay clientes</Text></View>
                                </View>
                            )}
                        </View>
                    </View>
                    {/* -------------------------------- Informacion de la reserva  -------------------------------- */}
                    <Text style={styles.sectionTitle}>Información Reserva</Text>
                    <Text style={styles.text}> Paquete Seleccionado: {Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0 ? (
                        reserva.paquetesturisticos.map(paquete => paquete.nombrePaqueteTuristico).join(', ')
                    ) : (
                        'No hay paquetes turísticos'
                    )}</Text>
                    {/* Información Reserva */}
                    <View style={styles.section}>
                        <View style={styles.section}>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>Destino:</Text></View>
                                    <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>Fecha Reserva:</Text></View>
                                    <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>Fecha Salida:</Text></View>
                                    <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>Fecha Llegada:</Text></View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>{Array.isArray(reserva.destinos) && reserva.destinos.length > 0 ? (
                                        reserva.destinos.map(destino => destino.nombreDestino).join(', ')
                                    ) : (
                                        'No hay destinos'
                                    )}</Text></View>
                                    <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>{reserva.fechaReserva?.slice(0, 10)}</Text></View>
                                    <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>{reserva.fechaSalida?.slice(0, 10)}</Text></View>
                                    <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>{reserva.fechaLlegada?.slice(0, 10)}</Text></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    {/* -------------------------------- Informacion costos del paquete -------------------------------- */}
                    <Text style={styles.sectionTitle}>Costos del Paquete</Text>F
                    {/* Costos del Paquete */}
                    <View style={styles.section}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>#</Text></View>
                                <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>Costo</Text></View>
                                <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>Cantidad</Text></View>
                                <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>Precio</Text></View>
                                <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>Subtotal</Text></View>
                            </View>
                            {reserva.costosReserva.map((costo, index) => (
                                <View style={styles.tableRow}>
                                    <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>{index + 1}</Text></View>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}>
                                        <Text style={styles.text}>
                                            {costo && costo.nombredelcostoReserva ? costo.nombredelcostoReserva : 'No Registra'}
                                        </Text>
                                    </View>
                                    <View style={[{ flex: 2, textAlign: 'center' }]}>
                                        <Text style={styles.text}>
                                            {costo && costo.cantidadcostoReserva ? costo.cantidadcostoReserva : 'No Registra'}
                                        </Text>
                                    </View>

                                    <View style={[{ flex: 3, textAlign: 'right' }]}>
                                        <Text style={styles.text}>
                                            {costo && costo.preciodelcostoReserva !== null && costo.preciodelcostoReserva !== undefined
                                                ? Number(costo.preciodelcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                : '0'}
                                        </Text>
                                    </View>

                                    <View style={[{ flex: 3, textAlign: 'right' }]}>
                                        <Text style={styles.text}>
                                            {costo && costo.subtotalcostoReserva !== null && costo.subtotalcostoReserva !== undefined
                                                ? Number(costo.subtotalcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                : '0'}
                                        </Text>
                                    </View>



                                </View>
                            ))}
                        </View>
                    </View>
                    {/* -------------------------------- Informacion Historial de Pagos -------------------------------- */}
                    <Text style={styles.sectionTitle}>Historial de Pagos</Text>
                    {/* Historial de Pagos */}
                    <View style={styles.section}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>#</Text></View>
                                <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>Fecha</Text></View>
                                <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>Medio de Pago</Text></View>
                                <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>Valor</Text></View>
                            </View>
                            {reserva.pagosOaportes.map((pago, index) => (
                                <View style={styles.tableRow}>
                                    <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>{index + 1}</Text></View>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}>
                                        <Text style={styles.text}>{pago.fechaAporte ? pago.fechaAporte.slice(0, 10) : 'No Registra'}</Text>
                                    </View>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}>
                                        <Text style={styles.text}>{pago.mediodepagoAporte ? pago.mediodepagoAporte.toUpperCase() : 'No Registra'}</Text>
                                    </View>

                                    <View style={[{ flex: 3, textAlign: 'right' }]}>
                                        <Text style={styles.text}>
                                            {(pago.valorAporte ?? 0).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
                                        </Text>
                                    </View>


                                </View>
                            ))}
                        </View>
                    </View>
                </View>

                {/* -------------------------------- informnacion de  pasajeros -------------------------------- */}

                <View style={styles.contentPasajeros}>
                    <Text style={styles.sectionTitle}>Información Pasajeros</Text>
                    {/* Información Pasajeros */}
                    <View style={styles.section}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>#</Text></View>
                                <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>Documento</Text></View>
                                <View style={[{ flex: 7, textAlign: 'center' }]}><Text style={styles.text}>Nombre</Text></View>
                                <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>Acomodación</Text></View>
                            </View>
                            {reserva.pasajeros.map((pasajero, index) => (
                                <View key={index} style={styles.tableRow}>
                                    <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>{index + 1}</Text></View>
                                    <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>{pasajero.documentoPasajero || 'No Registra'}</Text></View>
                                    <View style={[{ flex: 7, textAlign: 'center' }]}><Text style={styles.text}>{pasajero.nombreCompletoPasajero || 'No Registra'}</Text></View>
                                    <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>{pasajero.acomodacionPasajero ? pasajero.acomodacionPasajero.toUpperCase() : 'No Registra'}</Text></View>
                                </View>
                            ))}

                        </View>
                    </View>
                </View>

                {/* -------------------------------- Pie de Pagina -------------------------------- */}
                <View style={styles.footer}>
                    <Image src={Footer} />
                </View>
            </View>
        </Page>
    </Document >
);

function formatNumeroRecibo(numero) {
    return numero.toString().padStart(12, '0');
}


const ReservasPdf = () => {
    const { id } = useParams();
    const [reserva, setReserva] = useState(null);
    const [loading, setLoading] = useState(true);
    const { usuarios } = useUsuarios([]);
    const options = { headers: { authorization: "Bearer " + usuarios.token }, };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/api/reservas/${id}`, options);
                console.log('Response from API:', response); // Agregar console.log aquí
                setReserva(response.data.message);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener los datos de la reserva:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);


    //---------------------------------------------------------------------------

    /* const generateAndDownloadPDF = async () => {
        // Lógica para generar el contenido del PDF utilizando react-pdf
        const { pdf } = await import('@react-pdf/renderer');

        // Crea el blob con el contenido del PDF
        const blob = await pdf(<MyDocument reserva={reserva} />).toBlob();

        // Obtiene la fecha y hora actual
        const currentDate = new Date();
        const year = currentDate.getFullYear().toString();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Se agrega 1 ya que los meses van de 0 a 11
        const day = currentDate.getDate().toString().padStart(2, '0');
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');

        // Crea el nombre del archivo con el formato deseado
        const fileName = `JKT-RES${year}${month}${day}-${hours}${minutes}${seconds}.pdf`;

        // Crea un enlace para descargar el PDF
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        // Haz clic en el enlace para iniciar la descarga
        link.click();

    }; */

    const generateAndDownloadPDF = async () => {
        // Lógica para generar el contenido del PDF utilizando react-pdf
        const { pdf } = await import('@react-pdf/renderer');

        // Crea el blob con el contenido del PDF
        const blob = await pdf(<MyDocument reserva={reserva} />).toBlob();

        // Determina el nombre del archivo basado en el tipo de documento y número
        const fileName = reserva.tipoDocumento === 'COTIZACIÓN'
            ? `JKT-COTI${formatNumeroRecibo(reserva.numeroCotizacion)}.pdf`
            : `JKT-RES${formatNumeroRecibo(reserva.numeroReserva)}.pdf`;

        // Crea un enlace para descargar el PDF
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        // Haz clic en el enlace para iniciar la descarga
        link.click();
    };



    //----------------------------------------------------------------


    if (loading) {
        return <div>Cargando...</div>; // Muestra un indicador de carga mientras se obtienen los datos
    }

    if (!reserva) {
        return <div>No se encontró la reserva</div>; // Maneja el caso en el que la reserva no existe
    }

    // ----------------------------------------------------------------

    function formatNumeroRecibo(numero) {
        return numero.toString().padStart(12, '0');
    }

    return (
        <div>
            <div className="container mt-5 justify-content-end mt-3 mb-3 d-none d-lg-block">
                <div className="col text-end">
                    <button className="btn btn-success btn-lg" onClick={generateAndDownloadPDF} >
                        Generar PDF
                        <i className="btn fa-solid fa-file-pdf fa-2xl text-white"></i>
                    </button>
                </div>
            </div>

            <div className="container mt-5 mb-3 d-lg-none">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 text-center">
                        <button className="btn btn-success btn-lg w-100" onClick={generateAndDownloadPDF} >
                            Generar PDF
                            <i className="btn fa-solid fa-file-pdf fa-2xl text-white"></i>
                        </button>
                    </div>
                </div>
            </div>

            {/* Seccion para dispositivos pc o pantallas grandes */}
            <div className="d-none d-lg-block">
                <div id="pdf-content" className='container'>
                    {/* Encabezado */}
                    <div id="pdf-content" className='container border border-3 mb-3'>
                        <div id="header" className="header">
                            <div className="container mt-3">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-4 text-center mb-3 mb-md-0">
                                        <img src={LogoColor} alt="Logo" style={{ maxWidth: '100%' }} />
                                    </div>
                                    <div className="col-12 col-md-4 text-center mb-3 mb-md-0">
                                        <div style={{ fontWeight: 'bold', fontSize: '2rem' }}>Agencia Jukumaritravels</div>
                                        <div style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>RNT 138002 - NIT 71227531-3</div>
                                    </div>
                                    <div className="col-12 col-md-4 text-center">
                                        <div style={{ fontWeight: 'bold', fontSize: '2rem' }}>{reserva.tipoDocumento}</div>
                                        <div style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                                            {reserva.tipoDocumento === 'COTIZACIÓN'
                                                ? `COTI${formatNumeroRecibo(reserva.numeroCotizacion)}`
                                                : `RES${formatNumeroRecibo(reserva.numeroReserva)}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Contenido principal */}
                    <div className="main-content container border border-3 mb-3">
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col">
                                    <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Cliente</h2>
                                </div>
                            </div>
                        </div>

                        <div className="container" style={{ textAlign: 'left' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="container container-flex border-bottom mb-1 pb-2">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.nombre1Cliente}{cliente.apellido1Cliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </span>
                            </div>
                        </div>

                        <div className="container mt-2">
                            <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                <div className="col"><strong>Documento:</strong></div>
                                <div className="col"><strong>Teléfono:</strong></div>
                                <div className="col"><strong>Correo Electrónico:</strong></div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.documentoCliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </div>
                                <div className="col">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.telefono1Cliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </div>
                                <div className="col">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.emailCliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="container mt-5">
                            <div className="row">
                                <div className="col">
                                    <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Reserva</h2>
                                </div>
                            </div>
                        </div>
                        <div className="container" style={{ textAlign: 'left' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="container container-flex border-bottom mb-1 pb-2">
                                    {Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0 ? (
                                        <div>
                                            {reserva.paquetesturisticos.map(paquete => (
                                                <div key={paquete._id}>
                                                    {paquete.nombrePaqueteTuristico}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay paquetes turísticos'
                                    )}
                                </span>
                            </div>
                        </div>

                        <div className="container mt-2">
                            <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                <div className="col"><strong>Destino:</strong></div>
                                <div className="col"><strong>Fecha Reserva:</strong></div>
                                <div className="col"><strong>Fecha Salida:</strong></div>
                                <div className="col"><strong>Fecha Salida:</strong></div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {Array.isArray(reserva.destinos) && reserva.destinos.length > 0 ? (
                                        <div>
                                            {reserva.destinos.map(destino => (
                                                <div key={destino._id}>
                                                    {destino.nombreDestino}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay destinos'
                                    )}
                                </div>
                                <div className="col">{reserva.fechaReserva.slice(0, 10)}</div>
                                <div className="col">{reserva.fechaSalida.slice(0, 10)}</div>
                                <div className="col">{reserva.fechaLlegada.slice(0, 10)}</div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Costos del Paquete</h2>
                            </div>
                            <div className="container mt-2">
                                <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                    <div className="col"><strong>#</strong></div>
                                    <div className="col"><strong>Costo</strong></div>
                                    <div className="col"><strong>Cantidad</strong></div>
                                    <div className="col"><strong>Precio</strong></div>
                                    <div className="col"><strong>Subtotal</strong></div>
                                </div>
                                {reserva.costosReserva.map((costo, index) => (
                                    <div className="row" key={index}>
                                        <div className="col">{index + 1}</div>
                                        <div className="col">
                                            {costo.nombredelcostoReserva ? costo.nombredelcostoReserva : 'No Registra'}
                                        </div>
                                        <div className="col">
                                            {costo.cantidadcostoReserva ? costo.cantidadcostoReserva : 'No Registra'}
                                        </div>


                                        <div className="col d-flex justify-content-end align-items-center">

                                            <div className="col text-end">
                                                {costo && costo.preciodelcostoReserva !== null && costo.preciodelcostoReserva !== undefined
                                                    ? Number(costo.preciodelcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                    : '0'}
                                            </div>


                                        </div>
                                        <div className="col d-flex justify-content-end align-items-center">
                                            <div className="col text-end">
                                                {costo && costo.subtotalcostoReserva !== null && costo.subtotalcostoReserva !== undefined
                                                    ? Number(costo.subtotalcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                    : '0'}
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>


                        <div className="row mt-5">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Historial de Pagos</h2>
                            </div>
                            <div className="container mt-2 mb-5">
                                <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                    <div className="col-1"><strong>#</strong></div>
                                    <div className="col"><strong>Fecha</strong></div>
                                    <div className="col"><strong>Medio de Pago</strong></div>
                                    <div className="col"><strong>Valor</strong></div>
                                </div>
                                {reserva.pagosOaportes.map((pago, index) => (
                                    <div className="row" key={index}>
                                        <div className="col-1">{index + 1}</div>
                                        <div className="col"> {pago.fechaAporte ? pago.fechaAporte.slice(0, 10) : 'No Registra'}</div>
                                        <div className="col">
                                            {pago.mediodepagoAporte ? pago.mediodepagoAporte.toUpperCase() : 'No Registra'}
                                        </div>


                                        <div className="col d-flex justify-content-end align-items-center">

                                            <div className="col text-end">
                                                {pago.valorAporte !== null && pago.valorAporte !== undefined
                                                    ? pago.valorAporte.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                    : '0'}
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                    <div className='container border border-3 mb-3'>
                        <div className="row mt-3">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Pasajeros</h2>
                            </div>
                        </div>
                        <div className="container mt-2 mb-3">
                            <div className="row" style={{ backgroundColor: 'whitesmoke', fontSize: '0.9rem' }}>
                                <div className="col-1"><strong>#</strong></div>
                                <div className="col-3"><strong>Documento</strong></div>
                                <div className="col-5"><strong>Nombre</strong></div>
                                <div className="col-3"><strong>Acomodación</strong></div>
                            </div>
                            {reserva.pasajeros.map((pasajero, index) => (
                                <div className="row" key={index}>
                                    <div className="col-1">{index + 1}</div>
                                    <div className="col-3" style={{ fontSize: '0.9rem' }}>
                                        {pasajero.documentoPasajero ? pasajero.documentoPasajero : 'No Registra'}
                                    </div>
                                    <div className="col-5" style={{ fontSize: '0.9rem' }}>
                                        {pasajero.nombreCompletoPasajero ? pasajero.nombreCompletoPasajero : 'No Registra'}
                                    </div>

                                    <div className="col-3" style={{ fontSize: '0.9rem' }}>
                                        {pasajero.acomodacionPasajero ? pasajero.acomodacionPasajero.toUpperCase() : 'No Registra'}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Pie de página */}
                    <div className='container border border-3'>
                        <div id="footer" className="container container-flex footer mt-1">
                            <img src={Footer} alt="" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </div>
                </div>
            </div>


            {/* Seccion para dispositivos moviles o pantallas pequelas */}
            <div className="d-lg-none">
                <div id="pdf-content" className='container'>
                    {/* Encabezado */}
                    <div id="pdf-content" className='container border border-3 mb-3'>
                        <div id="header" className="header">
                            <div className="container mt-3">
                                <div className="row align-items-center">
                                    <div className="col-12 text-center mb-3">
                                        <img src={LogoColor} alt="Logo" style={{ maxWidth: '80%' }} />
                                    </div>
                                    <div className="col-12 text-center mb-3">
                                        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>Agencia Jukumaritravels</div>
                                        <div style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>RNT 138002 - NIT 71227531-3</div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div style={{ fontWeight: 'bold', fontSize: '2rem' }}>{reserva.tipoDocumento}</div>
                                        <div style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                                            {reserva.tipoDocumento === 'COTIZACIÓN'
                                                ? `COTI${formatNumeroRecibo(reserva.numeroCotizacion)}`
                                                : `RES${formatNumeroRecibo(reserva.numeroReserva)}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Contenido principal */}
                    <div className="main-content container border border-3 mb-3">
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col">
                                    <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Cliente</h2>
                                </div>
                            </div>
                        </div>

                        <div className="container" style={{ textAlign: 'left' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="container container-flex border-bottom mb-1 pb-2">
                                    <div className="d-flex align-items-center">
                                        <strong>Nombre:</strong>
                                        {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                            <div>
                                                {reserva.clientes.map(cliente => (
                                                    <div key={cliente._id}>
                                                        {cliente.nombre1Cliente}{cliente.apellido1Cliente}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            ' No hay clientes'
                                        )}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <strong>Email:</strong>
                                        {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                            <div>
                                                {reserva.clientes.map(cliente => (
                                                    <div key={cliente._id}>
                                                        {cliente.emailCliente}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            ' No hay clientes'
                                        )}
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div className="container" style={{ textAlign: 'left' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="container container-flex border-bottom mb-1 pb-2">

                                </span>
                            </div>
                        </div>

                        <div className="container mt-2">
                            <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                <div className="col"><strong>Documento:</strong></div>
                                <div className="col"><strong>Teléfono:</strong></div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.documentoCliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </div>
                                <div className="col">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.telefono1Cliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="container mt-5">
                            <div className="row">
                                <div className="col">
                                    <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Reserva</h2>
                                </div>
                            </div>
                        </div>

                        <div className="container" style={{ textAlign: 'left' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="container container-flex border-bottom mb-1 pb-2">
                                    <div className="d-flex align-items-center">
                                        <strong>Destino Turístico:</strong>
                                        {Array.isArray(reserva.destinos) && reserva.destinos.length > 0 ? (
                                            <div className="ms-2">
                                                {reserva.destinos.map(destino => (
                                                    <div key={destino._id}>
                                                        {destino.nombreDestino}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            ' No hay destinos'
                                        )}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <strong>Paquete Turístico:</strong>
                                        {Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0 ? (
                                            <div className="ms-2">
                                                {reserva.paquetesturisticos.map(paquete => (
                                                    <span key={paquete._id}>
                                                        {paquete.nombrePaqueteTuristico}
                                                    </span>
                                                ))}
                                            </div>
                                        ) : (
                                            <span className="ms-2">No hay paquetes turísticos</span>
                                        )}
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div className="container mt-2">
                            <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                <div className="col"><strong>F.Reserva:</strong></div>
                                <div className="col"><strong>F.Salida:</strong></div>
                                <div className="col"><strong>F.Salida:</strong></div>
                            </div>
                            <div className="row">
                                <div className="col">{reserva.fechaReserva.slice(0, 10)}</div>
                                <div className="col">{reserva.fechaSalida.slice(0, 10)}</div>
                                <div className="col">{reserva.fechaLlegada.slice(0, 10)}</div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Costos del Paquete</h2>
                            </div>
                            <div className="container mt-2">
                                <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                    <div className="col"><strong>Costo</strong></div>
                                    <div className="col"><strong>Cantidad</strong></div>
                                    <div className="col"><strong>Precio</strong></div>
                                    <div className="col"><strong>Subtotal</strong></div>
                                </div>
                                {reserva.costosReserva.map((costo, index) => (
                                    <div className="row" key={index}>
                                        <div className="col">
                                            {costo.nombredelcostoReserva ? costo.nombredelcostoReserva : 'No Registra'}
                                        </div>
                                        <div className="col">
                                            {costo.cantidadcostoReserva ? costo.cantidadcostoReserva : 'No Registra'}
                                        </div>

                                        <div className="col d-flex justify-content-end align-items-center">

                                            <div className='col text-end'>
                                                {costo && costo.preciodelcostoReserva !== null && costo.preciodelcostoReserva !== undefined
                                                    ? Number(costo.preciodelcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                    : '0'}
                                            </div>

                                        </div>
                                        <div className="col d-flex justify-content-end align-items-center">

                                            <div className="col text-end">
                                                {costo && costo.subtotalcostoReserva !== null && costo.subtotalcostoReserva !== undefined
                                                    ? Number(costo.subtotalcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                    : '0'}
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Historial de Pagos</h2>
                            </div>
                            <div className="container mt-2 mb-5">
                                <div className="row" style={{ backgroundColor: 'whitesmoke' }}>

                                    <div className="col"><strong>Fecha</strong></div>
                                    <div className="col"><strong>Medio P.</strong></div>
                                    <div className="col"><strong>Valor</strong></div>
                                </div>
                                {reserva.pagosOaportes.map((pago, index) => (
                                    <div className="row" key={index}>
                                        <div className="col">{pago.fechaAporte ? pago.fechaAporte.slice(0, 10) : 'No Registra'}</div>
                                        <div className="col">
                                            {pago.mediodepagoAporte ? pago.mediodepagoAporte.toUpperCase() : 'No Registra'}
                                        </div>

                                        <div className="col d-flex justify-content-end align-items-center">

                                            <div className='col text-end'>
                                                {pago && pago.valorAporte !== null && pago.valorAporte !== undefined
                                                    ? Number(pago.valorAporte).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                    : '0'}
                                            </div>


                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='container border border-3 mb-3'>
                        <div className="row mt-3">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Pasajeros</h2>
                            </div>
                        </div>
                        <div className="container mt-2 mb-3">
                            <div className="row" style={{ backgroundColor: 'whitesmoke', fontSize: '0.9rem' }}>
                                <div className="col-7"><strong>Nombre</strong></div>
                                <div className="col-3"><strong>Acomodación</strong></div>
                            </div>
                            {reserva.pasajeros.map((pasajero, index) => (
                                <div className="row" key={index}>
                                    <div className="col-7" style={{ fontSize: '0.9rem' }}>
                                        {pasajero.nombreCompletoPasajero ? pasajero.nombreCompletoPasajero : 'No Registra'}
                                    </div>

                                    <div className="col-3" style={{ fontSize: '0.9rem' }}>
                                        {pasajero.acomodacionPasajero ? pasajero.acomodacionPasajero.toUpperCase() : 'No Registra'}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Pie de página */}
                    <div className='container border border-3'>
                        <div id="footer" className="container container-flex footer mt-1">
                            <img src={Footer} alt="" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ReservasPdf;